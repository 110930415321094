<template>
  <span>{{ formatted }}<small>%</small></span>
</template>
<script>
export default {
  name: 'Percent',
  props: [ 'value', 'round' ],
  computed: {
    formatted: function () {
      let float = 0
      if (this.value) {
        if (this.round) {
          float = Number(parseFloat(this.value).toFixed(this.round))
        } else {
          float = parseFloat(this.value)
        }
      } else {
        return 0.0
      }

      return float
    }
  }
}
</script>
