<template>
  <b-row>
    <b-col sm="12">
      <b-row>
        <b-col sm="12" class="text-right mb-3">
          <button :disabled="pdfLoading" class="btn btn-sm btn-primary" @click="pdf()"><i class="fa fa-file"></i> Download PDF</button>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-card>
            <template slot="header">
              Client Information
            </template>
            <b-card-body>
              <b-row>
                <b-col sm="12">
                  <strong>{{ currentClient.primaryContact.firstName }} {{ currentClient.primaryContact.lastName }}</strong> <span class="ml-1" v-if="currentClient.primaryContact.birthday">({{ currentClient.primaryContact.age }})</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" v-if="currentClient.primaryContact.mobilePhone && currentClient.primaryContact.mobilePhone.number">
                  <strong><i class="fa fa-mobile-phone"></i></strong> {{ currentClient.primaryContact.mobilePhone.numberDashed }}
                </b-col>
                <b-col sm="6" v-if="currentClient.primaryContact.homePhone && currentClient.primaryContact.homePhone.number">
                  <strong><i class="fa fa-phone"></i></strong> {{ currentClient.primaryContact.homePhone.numberDashed }}
                </b-col>
              </b-row>
              <b-row v-if="currentClient.primaryContact.emailAddress">
                <b-col sm="12">
                  <strong><i class="fa fa-envelope-o"></i></strong> {{ currentClient.primaryContact.emailAddress.address }}
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card>
            <template slot="header">
              Before Infinite Banking
            </template>
            <b-card-body v-if="currentAnalysis.summary">
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Assets</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.before.assets.total"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Debts</strong></td>
                    <td class="text-right">- <dollars :value="currentAnalysis.summary.before.debts.total"></dollars></td>
                  </tr>
                  <tr>
                    <td class="border-top"><strong>Net Worth</strong></td>
                    <td class="text-right border-top">
                      <strong><dollars :value="currentAnalysis.summary.before.netWorth.total" class="colorize"></dollars></strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card>
            <template slot="header">
              Inputs
            </template>
            <b-card-body v-if="currentAnalysis && currentAnalysis.inputs">
              <table class="table b-table table-borderless table-sm">
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-right">Balance to Use</th>
                    <th class="text-right">Monthly to Use</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="input in currentStrategy.inputs" v-bind:key="input.id">
                    <td><strong>{{ input.name }}</strong></td>
                    <td class="text-right"><dollars :value="input.balanceToUse"></dollars></td>
                    <td class="text-right"><dollars :value="input.monthlyAmount"></dollars></td>
                  </tr>
                  <tr>
                    <td class="dashed-border-top"><strong>Sub-total</strong></td>
                    <td class="text-right dashed-border-top"><dollars :value="currentAnalysis.inputs.cashValuesToUse + currentAnalysis.inputs.otherToUse"></dollars></td>
                    <td class="text-right dashed-border-top"><dollars :value="currentAnalysis.inputs.monthly"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Taxes &amp; Penalties</strong></td>
                    <td class="text-right">- <dollars :value="currentAnalysis.inputs.penalties + currentAnalysis.inputs.taxes"></dollars></td>
                    <td class="text-right">- <dollars :value="0.00"></dollars></td>
                  </tr>
                  <tr>
                    <td class="dashed-border-top"><strong>Total</strong></td>
                    <td class="text-right dashed-border-top"><dollars :value="currentAnalysis.inputs.balanceAvailable"></dollars></td>
                    <td class="text-right dashed-border-top"><dollars :value="currentAnalysis.inputs.monthly"></dollars></td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-card>
            <template slot="header">
              Utilizing Infinite Banking - After 96 Months
            </template>
            <b-card-body v-if="currentAnalysis.summary">
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Assets</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.assets.total"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Cash Values </strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.policies.cashValues.total"></dollars></td>
                  </tr>
                  <tr>
                    <td class="dashed-border-top"><strong>Policy Loan Balance</strong></td>
                    <td class="text-right dashed-border-top">- <dollars :value="currentAnalysis.summary.after.withIBC.policies.loans.total"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Remaining Debt</strong></td>
                    <td class="text-right">- <dollars :value="currentAnalysis.summary.after.withIBC.debts.total"></dollars>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-top"><strong>Net Worth</strong></td>
                    <td class="text-right border-top">
                      <strong><dollars :value="currentAnalysis.summary.after.withIBC.netWorth.total" class="colorize"></dollars></strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Increase in net worth</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.netWorth.increase"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Annual Growth Rate</strong></td>
                    <td class="text-right"><percent :value="currentAnalysis.summary.after.withIBC.netWorth.CAGR * 100" round="1"></percent></td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Interest Paid</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.debts.interestPaid"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Interest Saved</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.debts.interestSaved"></dollars></td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Wind Current shifted YOUR direction</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.windCurrent.increase" postfix="mo"></dollars></td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card>
            <template slot="header">
              Without Utilizing Infinite Banking - After 96 Months
            </template>
            <b-card-body v-if="currentAnalysis.summary">
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Assets</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withoutIBC.assets.total"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Cash Values </strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withoutIBC.policies.cashValues.total"></dollars></td>
                  </tr>
                  <tr>
                    <td class="dashed-border-top"><strong>Policy Loan Balance</strong></td>
                    <td class="text-right dashed-border-top">N/A</td>
                  </tr>
                  <tr>
                    <td><strong>Remaining Debt</strong></td>
                    <td class="text-right">- <dollars :value="currentAnalysis.summary.after.withoutIBC.debts.total"></dollars>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-top"><strong>Net Worth</strong></td>
                    <td class="text-right border-top">
                      <strong><dollars :value="currentAnalysis.summary.after.withoutIBC.netWorth.total" class="colorize"></dollars></strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Increase in net worth</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withoutIBC.netWorth.increase"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Annual Growth Rate</strong></td>
                    <td class="text-right"><percent :value="currentAnalysis.summary.after.withoutIBC.netWorth.CAGR * 100" round="1"></percent></td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Interest Paid</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withoutIBC.debts.interestPaid"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Interest Saved</strong></td>
                    <td class="text-right">N/A</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Wind Current shifted YOUR direction</strong></td>
                    <td class="text-right">N/A</td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-card>
            <template slot="header">
              Total Outlay for Premiums
            </template>
            <b-card-body v-if="currentAnalysis.summary">
              <table class="table b-table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td><strong>Total Premium paid using Assets</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.policies.premiums.paidWithAssets"></dollars></td>
                  </tr>
                  <tr>
                    <td><strong>Additional Funds Needed</strong></td>
                    <td class="text-right"><dollars :value="currentAnalysis.summary.after.withIBC.policies.premiums.additionalFunds"></dollars></td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'

import axios from '@/axios'

export default {
  name: 'Summary',
  components: {
    Dollars,
    Percent
  },
  data: () => {
    return {
      pdfLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'global/currentClient',
      currentStrategy: 'global/currentStrategy',
      currentAnalysis: 'global/currentAnalysis'
    })
  },
  methods: {
    pdf () {
      this.pdfLoading = true
      axios({
        method: 'get',
        url: `/v1/strategies/${this.$route.params.strategyId}/pdf`,
        responseType: 'arraybuffer'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.currentClient.primaryContact.lastName} - Strategy.pdf`) //or any other extension
        document.body.appendChild(link)
        link.click()
        this.pdfLoading = false
      })
      .catch(() => {
        this.$snotify.error('Error Loading PDF')
      })
    }
  },
  watch: {

  },
  created () {
    this.pdfLoading = false
    if (this.currentClient.id != this.$route.params.id) {
      this.$store.dispatch('global/getClient', {
        id: this.$route.params.id
      })
    }
    if (this.currentStrategy.id != this.$route.params.strategyId) {
      this.$store.dispatch('global/getStrategy', {
        id: this.$route.params.strategyId
      })
    }

    this.$store.dispatch('global/getAnalysis', {
      id: this.$route.params.strategyId
    })
  }
}
</script>

<style>
  div.card-body {
    padding: 0.75em 1.25em 0.75em 1.25em;
  }
  table.table-sm td.numeric, table.table-sm th.numeric {
    text-align: right;
  }
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }
</style>
